<template>
  <div class="align-items-center" v-if="value">
    <button class="btn btn-secondary btn-sm" v-on:click="showModal()">
      <BaseIcon name="ruler" />
    </button>
    <span class="ms-3"
      >{{
        Math.round((total / (value.total * value.steps.length)) * 100)
      }}%</span
    >
  </div>
  <ManufacturingModal
    v-if="value && (extraValue === undefined || extraValue?.productionModal)"
    ref="modal"
    :steps="value?.steps"
    :id="value?.item_id ?? id"
    :warehouseOperationId="value?.warehouse_operation_id"
    :name="value?.title"
    v-model:total="value.total"
    v-model:done="value.done"
  />
</template>
<script>
import { defineAsyncComponent } from "vue";
import BaseIcon from "@/components/icons/BaseIcon";
const ManufacturingModal = defineAsyncComponent(() =>
  import("@/components/modals/ManufacturingModal")
);

export default {
  name: "Production",
  components: { ManufacturingModal, BaseIcon },
  props: {
    id: Number,
    row: Object,
    modelValue: Object,
    extraValue: {
      default() {
        return undefined;
      },
      type: Object,
    },
  },
  data() {
    return {
      value: this.modelValue,
    };
  },
  computed: {
    total() {
      let total = 0;
      for (const i in this.value.done) {
        total += this.value.done[i];
      }
      return total;
    },
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
      this.$emit("change");
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  methods: {
    showModal() {
      if (this.extraValue === undefined || this.extraValue?.productionModal) {
        this.$refs.modal.showModal();
      } else {
        window.apps.callEvent(
          "manufacturingModal:show",
          this.value?.item_id ?? this.id,
          this.value?.warehouse_operation_id
        );
      }
    },
  },
  emits: ["update:modelValue", "change"],
};
</script>
